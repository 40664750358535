import React from "react";
import BaseLayout from "../components/layout"
import { Link } from "gatsby"
import Seo from "./../components/seo";
import { StaticImage } from "gatsby-plugin-image"
import HorizontalRule from "../components/horizontal-rule";
import BreadcrumbTrail from "../components/breadcrumb-trail";

export default function NotFoundPage(props) {
  let breadcrumbList = [
    [
      {
        title: 'Home',
        url: '/'
      },
      {
        title: '404 Not Found',
      }
    ]
  ]

  return (
    <BaseLayout>
      <Seo title="404 Not Found" pathname={props.location.pathname} breadcrumbList={breadcrumbList}></Seo>
      <div className="layout-base-w app-p-base">
        <BreadcrumbTrail breadcrumbTrail={breadcrumbList[0]}></BreadcrumbTrail>
        <div className="text-mint mb-28">
          <HorizontalRule ruleType="1"></HorizontalRule>
          <h6 className="text-subheader-1 mb-4">Oh Shit, That Page Doesn't Exist</h6>
          <h1 className="text-header-1">Page Not Found (404)</h1>
        </div>

        <StaticImage className="w-full block app-image mx-auto" style={{'maxWidth': '640px'}} src="https://media.zackthoutt.com/img/skutull-puppy-poop.jpeg" alt="Skutull pooping as a puppy" />
      </div>
    </BaseLayout>
  )
}
